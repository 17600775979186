export enum AvenAdvisorTab {
    Overview = 'Overview',
    Spending = 'Spending',
    Accounts = 'Accounts',
    Home = 'Home',
    Investments = 'Investments',
    Shopping = 'Shopping',
    Rewards = 'Rewards',
}

export const avenAdvisorTabToClickEventName: Record<AvenAdvisorTab, string> = {
    [AvenAdvisorTab.Overview]: 'click_button_advisor_overview_tab',
    [AvenAdvisorTab.Home]: 'click_button_advisor_home_tab',
    [AvenAdvisorTab.Accounts]: 'click_button_advisor_accounts_tab',
    [AvenAdvisorTab.Spending]: 'click_button_advisor_spending_tab',
    [AvenAdvisorTab.Investments]: 'click_button_advisor_investments_tab',
    [AvenAdvisorTab.Rewards]: 'click_button_advisor_rewards_tab',
    [AvenAdvisorTab.Shopping]: 'click_button_advisor_shopping_tab',
}
